import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

   
<path d="M5458 10913 c-17 -4 -18 -28 -18 -322 0 -233 3 -320 12 -329 8 -8 53
-12 138 -12 132 0 451 -31 560 -55 36 -8 90 -17 120 -21 30 -3 75 -12 100 -19
25 -8 68 -17 95 -21 28 -4 61 -12 75 -19 14 -7 45 -15 69 -19 24 -4 56 -13 72
-21 15 -8 41 -15 56 -15 16 0 38 -7 49 -15 12 -8 39 -19 60 -24 43 -11 130
-40 155 -51 8 -5 47 -20 85 -35 38 -15 94 -39 124 -52 107 -48 205 -95 220
-104 8 -5 33 -18 55 -29 22 -11 72 -37 110 -60 39 -22 77 -43 85 -47 8 -4 26
-14 40 -23 14 -10 66 -44 115 -77 165 -108 356 -248 405 -295 14 -13 70 -62
125 -110 170 -146 410 -404 556 -598 92 -122 167 -227 180 -253 7 -12 24 -38
39 -57 15 -19 41 -60 57 -90 16 -30 43 -77 60 -104 17 -28 59 -106 94 -175 34
-69 66 -133 70 -141 5 -8 13 -26 19 -40 5 -14 16 -38 25 -53 8 -16 15 -33 15
-39 0 -6 8 -29 19 -52 51 -114 61 -138 61 -152 0 -8 9 -32 20 -54 11 -22 20
-49 20 -61 0 -11 7 -33 15 -47 8 -15 21 -54 30 -87 9 -33 23 -85 32 -115 8
-30 23 -91 33 -135 10 -44 23 -100 28 -125 20 -83 52 -284 63 -390 6 -58 15
-136 19 -175 5 -38 9 -124 10 -190 0 -80 4 -125 12 -133 16 -16 634 -17 644
-1 10 16 5 385 -7 459 -5 36 -16 124 -23 195 -8 72 -18 141 -24 155 -5 14 -13
58 -17 99 -4 41 -14 89 -21 107 -8 18 -14 47 -14 64 0 17 -9 56 -20 87 -11 32
-20 66 -20 78 0 11 -9 49 -19 85 -39 128 -110 346 -136 415 -14 39 -31 86 -37
105 -6 19 -17 44 -24 55 -8 11 -14 25 -14 32 0 19 -184 400 -236 488 -12 22
-28 49 -33 60 -6 11 -22 38 -36 60 -14 22 -28 47 -32 55 -3 8 -23 41 -44 72
-20 31 -40 66 -44 76 -3 11 -12 23 -20 26 -8 3 -15 10 -15 15 0 10 -124 190
-177 256 -13 17 -27 35 -31 40 -176 231 -460 531 -692 730 -57 50 -112 97
-121 105 -9 8 -28 24 -43 34 -15 11 -36 27 -46 35 -11 9 -50 38 -87 65 -37 27
-84 61 -103 75 -128 95 -511 328 -567 345 -13 4 -23 11 -23 16 0 5 -13 11 -29
15 -16 3 -35 13 -42 21 -6 8 -16 14 -22 14 -10 0 -38 12 -142 60 -22 10 -51
23 -65 29 -14 6 -32 15 -40 20 -35 20 -112 51 -128 51 -10 0 -22 4 -28 10 -10
10 -96 42 -174 64 -25 7 -54 18 -65 23 -20 11 -54 21 -170 50 -33 8 -69 19
-80 24 -20 10 -102 30 -200 50 -27 6 -72 17 -100 24 -27 8 -75 17 -105 20 -30
4 -82 13 -115 20 -33 8 -109 18 -169 24 -60 5 -112 14 -115 19 -6 9 -480 23
-513 15z"/>
<path d="M5451 9592 c-14 -27 -11 -607 4 -620 6 -6 93 -15 195 -20 102 -6 219
-17 260 -26 41 -8 100 -19 130 -24 102 -18 215 -44 225 -53 6 -4 31 -11 55
-15 25 -3 54 -12 66 -19 12 -8 39 -17 60 -21 21 -4 55 -15 74 -25 19 -11 42
-19 50 -19 9 0 25 -7 36 -15 10 -8 26 -15 34 -15 35 0 423 -207 517 -275 23
-16 58 -41 78 -55 207 -143 425 -349 630 -594 45 -55 104 -137 169 -237 61
-94 74 -115 95 -151 30 -50 171 -335 171 -345 0 -4 14 -42 31 -83 17 -41 37
-95 44 -120 7 -25 18 -58 24 -75 33 -94 72 -261 106 -455 9 -52 20 -173 24
-268 6 -135 10 -175 22 -183 7 -5 68 -9 134 -9 442 -2 488 -1 497 12 12 18 0
337 -17 428 -7 41 -16 104 -20 140 -3 36 -12 92 -20 125 -62 276 -71 309 -133
495 -50 151 -112 299 -182 441 -81 162 -97 192 -109 205 -5 7 -17 27 -25 45
-14 31 -29 56 -49 83 -4 6 -35 54 -70 106 -57 87 -183 258 -198 270 -3 3 -40
46 -81 95 -89 107 -325 341 -423 420 -38 31 -79 64 -90 73 -11 9 -49 38 -85
64 -36 26 -70 52 -77 57 -10 9 -71 50 -149 101 -78 51 -268 155 -379 209 -71
34 -143 69 -158 77 -16 8 -33 14 -39 14 -6 0 -29 8 -52 19 -44 19 -60 25 -201
72 -235 77 -387 115 -565 140 -41 6 -113 17 -160 24 -47 8 -164 17 -261 21
-172 7 -177 6 -188 -14z"/>
<path d="M5060 8299 c-36 -3 -74 -10 -85 -16 -11 -6 -43 -13 -72 -16 -29 -3
-65 -13 -82 -21 -16 -9 -44 -16 -62 -16 -17 0 -50 -8 -73 -19 -23 -10 -57 -24
-76 -31 -19 -6 -48 -17 -65 -22 -16 -6 -66 -28 -111 -50 -44 -21 -84 -38 -88
-38 -10 0 -214 -120 -271 -159 -27 -19 -64 -44 -80 -55 -17 -12 -37 -27 -45
-33 -8 -7 -46 -39 -85 -70 -109 -90 -222 -201 -288 -283 -33 -41 -67 -82 -74
-90 -52 -58 -63 -72 -63 -80 0 -5 -6 -15 -13 -22 -16 -17 -88 -126 -105 -158
-7 -14 -23 -43 -36 -65 -29 -50 -68 -125 -95 -187 -12 -27 -30 -67 -41 -91
-11 -24 -20 -53 -20 -64 0 -11 -7 -26 -15 -33 -8 -7 -15 -24 -15 -39 0 -14 -9
-46 -20 -70 -11 -24 -20 -54 -20 -67 0 -14 -9 -50 -20 -81 -11 -32 -20 -75
-20 -98 0 -22 -7 -62 -16 -90 -25 -75 -25 -699 -1 -765 9 -25 17 -61 17 -81 0
-20 9 -65 20 -99 11 -34 20 -72 20 -84 0 -11 7 -34 15 -50 8 -16 18 -47 21
-70 4 -22 12 -48 19 -56 7 -8 15 -28 19 -45 4 -16 13 -43 21 -60 47 -101 119
-246 141 -280 13 -22 27 -47 31 -55 4 -8 34 -55 66 -103 l59 -88 -7 -86 c-4
-48 -13 -103 -20 -123 -7 -21 -16 -64 -20 -96 -4 -33 -13 -77 -20 -99 -8 -22
-16 -67 -20 -100 -3 -33 -12 -78 -20 -100 -8 -22 -14 -59 -15 -81 0 -23 -9
-70 -20 -104 -11 -34 -20 -79 -20 -99 0 -20 -9 -63 -19 -96 -10 -33 -20 -89
-22 -125 l-4 -65 298 -3 c258 -2 298 0 303 13 4 8 17 15 31 15 14 0 36 9 50
20 14 11 35 20 45 20 11 0 31 9 45 20 14 11 36 20 48 20 12 0 28 7 35 15 7 8
17 15 23 15 5 0 31 9 56 21 25 12 64 29 86 39 79 34 141 63 177 81 20 11 46
19 56 19 11 0 25 7 32 15 15 19 40 19 77 1 47 -23 125 -47 178 -56 28 -5 67
-15 87 -24 21 -9 53 -16 71 -16 19 0 56 -7 83 -15 77 -25 364 -37 604 -26 114
5 217 14 227 20 11 5 57 15 102 20 45 6 92 17 104 26 12 8 37 15 57 15 20 0
45 6 56 14 11 8 38 17 60 21 21 4 49 13 61 21 12 8 28 14 35 14 7 0 36 11 63
25 28 13 55 25 61 25 6 0 17 7 24 15 7 8 22 15 34 15 12 0 32 7 45 16 13 9 49
30 80 47 63 33 270 168 316 205 222 181 411 384 553 593 29 42 59 92 67 112 8
19 18 37 22 39 10 4 103 189 103 204 0 6 7 17 15 24 8 7 15 21 15 31 0 11 9
36 20 56 11 21 20 45 20 53 1 8 9 33 20 55 11 22 19 50 20 63 0 13 6 35 14 50
7 15 17 49 20 75 4 26 13 62 21 80 19 48 30 204 15 222 -10 13 -63 15 -330 15
-243 0 -320 -3 -327 -12 -11 -17 -41 -110 -53 -168 -19 -100 -95 -284 -165
-403 -22 -37 -47 -80 -55 -95 -36 -64 -163 -222 -240 -300 -53 -53 -243 -212
-254 -212 -3 0 -31 -17 -63 -38 -75 -49 -87 -56 -153 -87 -131 -62 -166 -76
-252 -100 -25 -8 -55 -18 -65 -23 -10 -6 -53 -15 -94 -21 -42 -6 -87 -14 -100
-19 -38 -15 -314 -23 -429 -13 -124 11 -228 28 -290 48 -25 8 -63 18 -85 22
-22 5 -53 16 -70 25 -16 9 -36 16 -43 16 -14 0 -173 72 -202 91 -8 5 -27 15
-42 22 -16 6 -28 15 -28 19 0 4 -15 14 -32 23 -39 17 -65 34 -88 56 -10 9 -44
36 -76 60 -33 24 -95 79 -140 124 -80 80 -244 278 -244 295 0 5 -13 28 -28 52
-48 72 -87 152 -139 283 -35 87 -45 119 -55 170 -6 28 -16 65 -24 83 -8 18
-19 99 -25 180 -5 81 -14 160 -20 176 -12 40 -11 228 2 263 6 15 15 65 20 109
5 45 16 95 24 110 8 16 15 42 15 57 0 15 9 45 20 67 11 22 20 47 20 57 0 10 8
32 18 48 10 17 22 42 25 57 4 15 21 50 37 78 17 28 30 54 30 57 0 6 130 204
140 213 3 3 38 43 77 91 97 115 250 250 371 325 35 21 79 49 98 62 19 12 43
22 53 22 10 0 26 9 36 20 10 11 28 20 40 20 13 0 28 7 35 15 7 8 30 18 51 21
21 4 44 13 50 20 6 8 23 14 38 14 14 1 44 8 66 16 22 9 73 22 113 29 41 8 88
23 105 33 l32 18 3 305 c1 167 0 314 -3 325 -6 26 -34 31 -125 23z"/>
<path d="M5467 8284 c-4 -4 -7 -137 -7 -296 l1 -288 22 -4 c12 -3 67 -7 122
-10 55 -3 118 -12 140 -20 22 -9 57 -15 78 -16 22 0 51 -6 65 -14 15 -8 48
-18 74 -21 26 -3 50 -10 53 -15 3 -5 25 -14 48 -20 23 -7 76 -27 117 -47 41
-19 84 -39 95 -44 11 -5 34 -18 50 -29 17 -11 37 -23 45 -27 31 -15 146 -92
166 -112 12 -12 26 -21 31 -21 6 0 61 -49 122 -110 61 -60 120 -110 129 -110
26 0 42 -20 42 -51 0 -15 7 -32 15 -39 8 -7 15 -18 15 -26 0 -7 6 -17 13 -21
12 -8 99 -135 111 -163 3 -8 19 -37 35 -65 16 -27 30 -57 30 -66 1 -9 10 -24
21 -34 11 -10 20 -29 20 -42 0 -13 4 -31 10 -41 22 -39 59 -150 65 -197 4 -27
12 -56 17 -63 6 -7 14 -47 19 -90 4 -42 13 -125 20 -184 l12 -108 299 0 298 0
0 131 c0 72 -4 139 -10 149 -5 10 -15 62 -21 116 -6 55 -16 110 -23 124 -8 14
-16 44 -20 68 -3 23 -17 75 -31 115 -65 190 -54 162 -114 287 -22 47 -45 96
-51 110 -6 14 -20 42 -33 62 -12 20 -37 63 -56 95 -19 32 -39 63 -45 68 -7 6
-23 28 -36 50 -39 66 -231 292 -290 341 -14 12 -45 41 -70 65 -65 64 -133 119
-146 119 -7 0 -14 7 -18 15 -3 8 -12 15 -21 15 -8 0 -15 4 -15 9 0 8 -66 56
-100 74 -8 4 -33 18 -55 32 -22 14 -51 31 -65 38 -14 6 -31 17 -38 24 -7 7
-24 16 -37 19 -13 3 -26 12 -29 19 -3 8 -21 17 -40 20 -19 4 -40 13 -47 21 -6
8 -19 14 -28 14 -9 0 -29 8 -46 18 -16 10 -43 22 -60 26 -16 4 -43 13 -60 20
-59 26 -160 56 -190 56 -17 0 -40 6 -50 14 -11 8 -52 17 -90 21 -38 4 -83 13
-100 20 -34 15 -346 31 -358 19z"/>
<path d="M4545 6994 c-55 -8 -219 -47 -235 -55 -8 -4 -55 -27 -105 -50 -75
-36 -105 -58 -181 -134 -112 -111 -171 -202 -223 -345 -73 -200 -90 -532 -41
-776 78 -387 331 -650 691 -719 49 -9 136 -15 223 -15 194 0 294 22 445 96
103 50 125 66 206 148 50 50 105 115 121 143 16 29 32 53 37 53 4 0 7 -86 7
-190 0 -172 -2 -191 -17 -198 -10 -4 -49 -16 -88 -27 -57 -16 -71 -24 -73 -42
-3 -17 3 -24 23 -29 40 -10 1071 4 1135 15 160 29 247 53 323 90 176 85 272
212 297 392 21 145 -28 290 -126 377 -110 98 -222 148 -424 188 -68 14 -126
26 -128 28 -1 2 23 7 55 10 156 19 313 82 402 162 30 27 66 73 84 108 31 58
32 66 32 176 0 108 -2 119 -31 180 -75 157 -224 258 -459 312 -84 20 -127 21
-637 25 l-548 4 0 -24 c0 -22 8 -26 90 -48 l90 -23 0 -138 c0 -76 -3 -138 -7
-138 -5 1 -26 30 -48 66 -43 70 -150 179 -230 234 -66 45 -192 97 -295 121
-83 20 -298 33 -365 23z m233 -95 c100 -50 165 -138 225 -304 117 -326 117
-974 0 -1300 -61 -169 -126 -256 -228 -305 -82 -39 -128 -39 -211 0 -216 103
-327 474 -311 1033 3 111 13 251 21 311 37 265 133 473 253 547 93 57 163 63
251 18z m1477 -95 c71 -36 131 -101 176 -191 34 -67 34 -69 34 -204 l0 -136
-39 -76 c-45 -90 -103 -147 -184 -185 -49 -23 -71 -27 -152 -27 l-95 0 -3 429
-2 429 104 -5 c89 -5 113 -10 161 -34z m18 -920 c77 -20 108 -36 159 -82 112
-101 163 -274 138 -474 -20 -164 -100 -287 -229 -354 -51 -26 -64 -28 -203
-32 l-148 -4 0 474 c0 261 3 478 7 481 13 14 215 7 276 -9z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
